import React from 'react';
import { Box, Container, Typography, Link, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const StyledFooter = styled('footer')(({ theme }) => ({
  backgroundColor: 'rgba(0, 30, 60, 0.5)', // Dark blue with some transparency
  color: theme.palette.common.white,
  padding: theme.spacing(4, 0),
  marginTop: 'auto',
}));

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              {t('title')}
            </Typography>
            <Typography variant="body2">
              Servicii profesionale de certificare energetică pentru apartamente și clădiri.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              {t('priceInfo.title')}
            </Typography>
            <Typography variant="body2">
              {t('priceInfo.studio')}<br />
              {t('priceInfo.twoRooms')}<br />
              {t('priceInfo.threeRooms')}<br />
              {t('priceInfo.fourRooms')}<br />
              {t('priceInfo.other')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Contact
            </Typography>
            <Typography variant="body2">
              Telefon: 0784 234 803<br />
              Email: cenergeticana@gmail.com
            </Typography>
          </Grid>
        </Grid>
        <Box mt={3} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body2" align="center">
            © {currentYear} GX3 ENERGY PROWESS SOLUTIONS SRL. Toate drepturile rezervate.
          </Typography>
          <Box mt={1}>
            <Link component={RouterLink} to="/privacy-policy" color="inherit" sx={{ mr: 2, color: 'rgba(255, 255, 255, 0.7)' }}>
              Politica de confidențialitate
            </Link>
            <Link component={RouterLink} to="/terms-and-conditions" color="inherit" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              Termeni și condiții
            </Link>
          </Box>
        </Box>
      </Container>
    </StyledFooter>
  );
};

export default Footer;