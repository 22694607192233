import React, { useState } from 'react';
import { AppBar, Toolbar, Tabs, Tab, Box, IconButton, Menu, MenuItem, useMediaQuery, useTheme, Breadcrumbs, Link, Typography, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../assets/logo-text.webp';

const Header = () => {
    const [value, setValue] = useState(0);
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const menuItems = [
        { label: 'Certificate Energetice', href: '/' },
    ];

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                GX3
            </Typography>
            <List>
                {menuItems.map((item) => (
                    <ListItem key={item.label} component="a" href={item.href}>
                        <ListItemText primary={item.label} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar position="sticky" sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', boxShadow: 'none', backdropFilter: 'blur(10px)' }}>
            <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center', padding: '0 16px', minHeight: '64px', flexWrap: 'wrap' }}>
                <Box component="a" href="/" sx={{ cursor: 'pointer', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                    <img src={logo} alt="Logo GX3" style={{ height: '50px', marginTop: '10px' }} />
                </Box>
                
                <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />} sx={{ flexGrow: 1, ml: 2, display: { xs: 'none', sm: 'flex' } }}>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="inherit"
                        href="/"
                    >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Acasă
                    </Link>
                    <Typography color="text.primary">Certificate Energetice</Typography>
                </Breadcrumbs>

                {isMobile ? (
                    <>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ ml: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </>
                ) : (
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="inherit"
                        indicatorColor="primary"
                        sx={{ height: '64px' }}
                    >
                        {menuItems.map((item, index) => (
                            <Tab
                                key={index}
                                label={item.label}
                                component="a"
                                href={item.href}
                                sx={{
                                    minHeight: '64px',
                                    height: '64px',
                                    color: theme.palette.text.primary,
                                }}
                            />
                        ))}
                    </Tabs>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;