import React from 'react';
import { Typography, Box, Button } from '@mui/material';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log the error to an error reporting service here
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Oops! Ceva nu a mers bine.
          </Typography>
          <Typography variant="body1" paragraph>
            Ne cerem scuze pentru inconvenient. Vă rugăm să încercați din nou mai târziu sau să ne contactați dacă problema persistă.
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => window.location.reload()}
          >
            Reîncărcați pagina
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;